.title {
  color: rgb(15, 27, 36);
  font-family:
          Open Sans,
          serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 40px;
}

.tableContainer {
  min-height: initial !important;
  padding: 0 0 30px;
}

.aboutIcon {
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.16);

  svg > path {
    fill: rgb(71, 85, 105);
  }

  &:hover {
    background: transparent;
  }
}

.entity {
  &_title {
    text-align: center;
    color: rgb(15, 27, 36);
    font-family: Open Sans, serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }

  &_container {
    padding-top: 20px;
    max-height: 313px;
    overflow-y: auto;
  }

  &_aboutItem {
    display: flex;
    flex-direction: column;
    padding: 8px 0 8px 0;
    border-top: 1px solid rgba(132, 143, 152, 0.16);
    gap: 4px;
    position: relative;

    &:last-child {
      border-bottom: 1px solid rgba(132, 143, 152, 0.16);
    }
  }

  &_indicator {
    height: 10px;
    position: absolute;
    bottom: 0;
    background: green;
  }

  &_aboutItemLabel {
    color: rgba(15, 27, 36, 0.64);
    font-family: SFProText-Regular, serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;

    & > span {
      color: rgb(15, 27, 36);
      font-family: SFProText-Regular, serif;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
    }
  }

  &_download {
    text-decoration: underline;
    cursor: pointer;
  }

  &_aboutItemValue {
    color: rgb(15, 27, 36);
    font-family: Open Sans, serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  }
}

.removeContent {
  border-radius: 24px;
  background: rgb(241, 242, 243);

  &_actions {
    display: flex;
    padding-top: 20px;
    gap: 8px;
  }

  &_actions_cancel {
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.16);
    color: rgb(15, 27, 36);

    &:hover {
      background: transparent;
    }
  }

  & > h3 {
    text-align: center;
    color: rgb(15, 27, 36);
    font-family: Open Sans, serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }
}
