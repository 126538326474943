.container {
  background: white;
}

.eyeDropperIcon {
  cursor: url(../../assets/iconsEditor/dropper.svg) 0 16, auto;
}

.zoomInIcon {
  cursor: url(../../assets/iconsBtn/zoomIn.svg) 0 16, auto;
}

.zoomOutIcon {
  cursor: url(../../assets/iconsBtn/zoomOut.svg) 0 16, auto;
}

.fillFloodIcon {
  cursor: url(../../assets/iconsEditor/paint.svg) 16 16, auto;
}

.popup {
  max-width: 330px;
  padding: 32px 29px 25px 29px;
  background: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  text-align: center;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &_icon {
    position: absolute;
    right: 9px;
    top: 9px;
    cursor: pointer;
  }
}
