.background {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgb(0 0 0 / 40%);
  top: 0;
  overflow: hidden;
  z-index: 9;
}

.modal {
  border-radius: 4px;
  background: white;
  margin: auto;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: max-content;
  height: max-content;
  padding: 36px;
  z-index: 999999;

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }
}
